<template>
    <!--begin::Row-->
    <div class="row g-5 g-xl-8">
      <div class="col-xl-12">
        <div class="card mb-xl-8">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Heading-->
            <div class="text-center">
              <!--begin::Title-->
              <div class="card-label fw-bolder fs-3 mb-3">{{ $t("ogza.chatGptSettings.title") }}</div>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Form-->
            <Form
              id="kt_account_profile_details_form"
              class="form"
              :validation-schema="updateChatGptForm"
            >
              <!--begin::Card body-->
              <div class="card-body p-9 ps-6 pb-1">
                <div class="row mb-0">
                  <div class="col-md-12">
                    <!--begin::Input group-->
                    <div class="fv-row mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-6 fw-bolder text-dark required"
                        >{{ $t("ogza.chatGptSettings.activeVersion") }}</label
                      >
                      <!--end::Label-->
  
                      <!--begin::Input-->
                      <Field
                      name="type"
                      data-control="select1"
                      data-hide-search="true"
                      data-placeholder='Versiyon seçiniz...'
                      class="form-select form-select-solid"
                      as="select"
                    >
                    <option value="0" selected>
                      {{ $t("ogza.chatGptSettings.selectVersion") }}
                    </option>
                      <option>
                       4.0
                      </option>
                      <option>
                       3.5
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="type" />
                      </div>
                    </div>
                    </div>
                    <!--end::Input group-->
                  </div>
                </div>
                <div class="row mb-0">
                <div class="col-md-12">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark required"
                      > {{ $t("ogza.chatGptSettings.apiKey") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="apiKey"
                      autocomplete="off"
                      :placeholder="$t('ogza.chatGptSettings.enterApiKey')"   
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="apiKey" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
                <div class="d-flex justify-content-center">
                  <!--begin::Submit button-->
                  <button
                    type="submit"
                    id="kt_account_profile_details_submit"
                    ref="submitButton"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{ $t("common.button.save") }}</span>
                    <span class="indicator-progress">
                      {{ $t("common.pleaseWait") }}
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span>
                    </span>
                  </button>
                  <!--end::Submit button-->
                </div>
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <!--end::Row-->
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted } from "vue";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import * as Yup from "yup";
  import { useI18n } from "vue-i18n";
  
  export default defineComponent({
    name: "update-chatGpt",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
  
      //Create form validation object
      const updateChatGptForm = Yup.object().shape({
        type: Yup.string()
        .required(t("validators_error.required"))
        .label("Type"),
        apiKey: Yup.string()
        .required(t("validators_error.required"))
        .label("ApiKey"),
      });
  
      onMounted(() => {
        setCurrentPageTitle(t("ogza.chatGptSettings.title"));
      });
  
      return {
        updateChatGptForm,
      };
    },
  });
  </script>
  